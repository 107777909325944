import React, { useState } from 'react';
import "./forgotPassword.css";
import forgotPassImg from "../../../images/forgotPassImg.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../../../actions/authActions';
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa6";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId"); 
    const [visible, setVisible] = useState(false)
    

    const [formData, setFormData] = useState({ password: '', confirmPassword:'' });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
      if (!formData.password) {
        Swal.fire('Error', 'Password is required', 'error');
        return false;
    }
    if (formData.password.length < 6) {
        Swal.fire('Error', 'Password must be at least 6 characters long', 'error');
        return false;
    }
    if (formData.password !== formData.confirmPassword) {
        Swal.fire('Error', 'Passwords do not match', 'error');
        return false;
    }
    return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            dispatch(resetPassword(userId, formData))
                .then(() => {
                    Swal.fire('Success', 'Password Reset Successfully', 'success').then(() => {
                        navigate('/');
                    });
                })
                .catch((error) => {
                    Swal.fire('Error', error, 'error');
                })
                .finally(() => setLoading(false));
        }
    };
    return (
        <div className='forgotPassWord-container'>
            <form className="forgotPassWord-container-left" onSubmit={handleSubmit}>
                <h1>Reset Password?</h1>
                <div className="forgotPassWord-container-right-item1">
                    <label htmlFor="">Enter New Password</label>
                    <div className="forgotPassWord-container-right-item1-inputhlder">
                        <input type={visible ? "text" : 'password'} placeholder='password' name='password' value={formData.password} onChange={handleChange} />
                        {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
                    </div>
                    
                </div>
                <div className="forgotPassWord-container-right-item1">
                    <label htmlFor="">Confirm Password</label>
                    <div className="forgotPassWord-container-right-item1-inputhlder">
                    <input type={visible ? "text" : 'password'} placeholder='confirm Password' name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} />
                    {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
                    </div>
                    
                </div>
                <button type='submit' disabled={loading}>
                    {loading ? 'Resetting...' : 'Reset'}
                </button>
            </form>

            <div className="forgotPassWord-container-right">
                <img src={forgotPassImg} alt="Forgot Password Illustration" />
            </div>
        </div>
    );
};

export default ForgotPassword;
