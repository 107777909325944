import React, { useEffect } from 'react'
import "./notification.css"
import leftArrow from "../../../Company/images/arrowLeft.svg"
import notifyMe from "../../../Company/images/notifyMe.svg"
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationsAdmin } from '../../../../actions/authActions'

const Notification = () => {
  const dispatch= useDispatch()
  const token = useSelector((state)=>state.auth.token)
  const notification = useSelector((state)=>state.auth.notifications)
  
  useEffect((token)=>{
    dispatch(getNotificationsAdmin(token))
  },[token, dispatch])
  return (
    <div className='company-notification-container'>
      <div className="comNot-main">
        <div className='comNot-headline-conatiner'>
          <img src={leftArrow} alt="" />
          <h2>Notifications</h2>
        </div>
        <div className='comNot-filter-conatiner'>
          <select name="" id="">
            <option value="">Recent</option>
            <option value="">Older</option>
            <option value="">Unread</option>
            <option value="">Read</option>
          </select>
        </div>
        <div className='comNot-subheadline-conatiner'></div>
        <div className='comNot-item-conatiner'>
          <div className='comNot-item'>
            <img src={notifyMe} alt="" />
            <p>A customer has successfully purchased a ticket. View details <span>here</span></p>
            <span>10:14 am</span>
          </div>
          <div className='comNot-item'></div>
        </div>
      </div>
      
    </div>
  )
}

export default Notification
