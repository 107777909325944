import React, { useState, useEffect } from "react";
import "./transportCompnyDetails.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import { MdStarRate } from "react-icons/md";
import { FaTag } from "react-icons/fa6";
import { FaNairaSign } from "react-icons/fa6";
import igLogo from "../../images/igIcon.png"
import fbLogo from "../../images/fbIcon.png"
import twitterLogo from "../../images/twitterIcon.png"
import motorIcon from "../../images/bus.png"
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { bookTicket, getAvailableSeats } from "../../../../actions/authActions";


const TransportCompanyDetailes = () => {
    const [transportAbout, setTransportAbout] = useState(true)
    const [transportTrips, setTransportTrips] = useState(false)
    const [transportTerminals, settransportTerminals] = useState(false)
    const [viewTerminals, setViewTerminals] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
    const { tranCompanyID } = location.state || {};
    const transportCompanyData = useSelector((state)=>state.auth?.transportCompanies?.data?.find(company=> company.tranCompanyID === tranCompanyID) || [])
    const trips = useSelector((state)=>state.auth?.trips?.data || [])
    const companyTrips = trips.filter((trip)=> trip.transCompany?.companyName === transportCompanyData.companyName)
    const dispatch = useDispatch()
    const token = useSelector((state)=>state.auth?.token)
    const ticketId = useSelector((state)=>state.auth.bookedTicket?.ticket?._id || '')
  
    // const [searchInput, setSearchInput] = useState({
    //     to: '',
    //     from:'',
    //     adult: "1",
    //     children: "0",
    //     date: '',
    //     tripType: 'One Way',
    //     from_time: '',
    //     to_time: ''
    //   })
    //   const handleSearchDetails = (companyTrip) => {
    //     setSearchInput((prevState) => {
    //       const updatedInput = {
    //         ...prevState,
    //         from: companyTrip.from || "",
    //         to: companyTrip.to || "",
    //         date: new Date().toISOString().split("T")[0],
    //         from_time: companyTrip.from_time || "",
    //         to_time: companyTrip.to_time || "",
    //         tripType: companyTrip.tripType || "", 
    //       };
      
    //       // Dispatch actions inside the callback where we have access to the latest state
    //       dispatch(bookTicket(updatedInput, companyTrip.transCompany?._id || "", token));
      
    //       if (ticketId) {
    //         dispatch(getAvailableSeats(ticketId, token));
    //       }
      
    //       return updatedInput; // This ensures React updates the state correctly
    //     });
      
    //     navigate('/selectseat');
    //   };
      
      const handleSearchDetails = (companyTrip) => {
        
        const updatedSearchInput = {
          from: companyTrip.from || '',
          to: companyTrip.to || '',
          from_time: companyTrip.from_time || '',
          to_time: companyTrip.to_time || '',
          date: companyTrip?.date || new Date().toISOString().split('T')[0], 
          tripType: companyTrip.tripType || '',
          adult: '1',
          children: '0',
        };
        
          if (updatedSearchInput) {
            dispatch(bookTicket(updatedSearchInput, companyTrip.transCompany?._id || "", token));
          }
        };
      
        useEffect(() => {
          if (ticketId) {
            navigate('/selectseat');
            dispatch(getAvailableSeats(ticketId, token));
          }
        }, [ticketId, dispatch, navigate, token]);
    

    const handleViewStatus = ()=>{
        setViewTerminals(!viewTerminals)
    }

    const handleSocialMediaClick = (platform, username) => {
        if (!username) {
          alert("No social media handle available for this platform.");
          return;
        }
    
        let url = "";
        switch (platform) {
          case "facebook":
            url = `https://www.facebook.com/${username}`;
            break;
          case "instagram":
            url = `https://www.instagram.com/${username}`;
            break;
          case "twitter":
            url = `https://twitter.com/${username}`;
            break;
          default:
            break;
        }
    
        window.open(url, "_blank");
      };

    return (
        <div className="TransportDetails-mainContainer">
            <div className="TransportDetails-main">
                <div className="traveleerSide-ttransporcompanies-topsec123">
                    <h2>TRANSPORT COMPANIES</h2>
                </div>
                <div className="transportDetails-topsec">
                    <IoMdArrowBack /> <h5>Back</h5>
                </div>
                <div className="transportDetails-sec2">
                    <img src={transportCompanyData.url} alt="" />
                    <div className="transportDetails-sec2a">
                        <h3>{transportCompanyData.companyName}</h3>
                        <div className="transportDetails-Rating">
                            4.5 <MdStarRate />
                        </div>
                        <div className="transportDetails-priceTag">
                            <FaTag  color="red" size={15}/> <>Starting from <div><FaNairaSign size={12} /> 12,500</div></>
                        </div>
                        <h6>Destinations : Lagos, Abuja, Port Harcourt, Ibadan,
                            Enugu </h6>


                    </div>
                </div>
                <div className="transportDetails-sec3">
                    <div className="transportDetails-sec3-top">
                        <p className={transportAbout && "transportDetails-sec3-top-active"} onClick={() => {
                            setTransportAbout(!transportAbout)
                            setTransportTrips(false)
                            settransportTerminals(false)
                        }}>About</p>
                        
                        <p className={transportTrips && "transportDetails-sec3-top-active"} onClick={() =>
                            {   setTransportTrips(!transportTrips)
                                setTransportAbout(false)
                                settransportTerminals(false)
                            }}>Available Trips</p>
                        
                        <p className={transportTerminals && "transportDetails-sec3-top-active"} onClick={() =>{
                             settransportTerminals(!transportTerminals)
                            setTransportTrips(false)
                            setTransportAbout(false)
                             }}>Terminals</p>
                    </div>
                    {
                        transportAbout ? 

                        <div className="transportDetails-sec4">
                    <p>{transportCompanyData.aboutUs}</p>
                   <>
                   <h5>Photos</h5>
                   <div className="tranportDetails-imgHolder">
                        <img src="" alt="" />
                        <img src="" alt="" />
                        <img src="" alt="" />
                    </div>
                   </>
                   <>
                   <h5>Socials</h5>
                   <div className="transportDetails-Socials">
                             <div className="transportDetails-Socials-item1" onClick={() => handleSocialMediaClick("instagram", transportCompanyData.instagram)}>
                                <img src={igLogo} alt="" /> <h5>Instagram</h5>
                             </div>
                             <div className="transportDetails-Socials-item2" onClick={() => handleSocialMediaClick("facebook", transportCompanyData.facebook)}>
                                <img src={fbLogo} alt="" /> <h4>Facebook</h4>
                             </div>
                             <div className="transportDetails-Socials-item3" onClick={() => handleSocialMediaClick("twitter", transportCompanyData.twitter)}>
                                <img src={twitterLogo} alt="" /> <h5>Twitter</h5>
                             </div>
                   </div>
                   </>
                </div>:

                        transportTrips ?

                        <div className="transportDetails-trips">
                            { companyTrips.length > 0 ? 
                            companyTrips.map((companyTrip)=>(
                                <div className="transportDetails-trips-item1" key={companyTrip._id} onClick={() => handleSearchDetails(companyTrip)}>
                                <div className="transportDetails-trips-item1-top">
                                        <p>{companyTrip._id}</p>
                                </div>
                                <div className="transportDetails-trips-item1-mid">
                                    <div className="transportDetails-trips-item1-mida">
                                        <p>{companyTrip.from}</p>
                                        <p>{companyTrip.from_time}</p>
                                    </div>
                                    
                                    <div className="transportDetails-trips-item1-midb">
                                        <div className="locationDot2"></div>
                                        <img src={motorIcon} alt="" />
                                        <div className="locationDot2"></div>
                                    </div>
                                   
                                    <div className="transportDetails-trips-item1-midc">
                                    <p>{companyTrip.to_time}</p>
                                    <p>{companyTrip.to}</p>
                                    </div>
                                </div>
                                <div className="transportDetails-trips-item1-btm">
                                    <h5>16 seats</h5>
                                    <div className="transportDetails-trips-item1-btmA"><FaNairaSign size={12} /> <p>{companyTrip.amount} / person</p></div>
                                </div>
                            </div>
                            )):
                            <p style={{padding:'20px'}}>No trip available for this Company</p>
                            }
                                

                                
                                
                        </div> : 

                        transportTerminals ?
                         <div className="transportDetails-Terminals">
                                <div className="transportDetails-Terminals-item1">
                                    <div className="transportDetails-Terminals-item1a">
                                    <h4 onClick={handleViewStatus}>Lagos</h4>
                                    {viewTerminals ? <>
                                        <FaAngleDown />
                                    </> : <>
                                        <FaAngleRight />
                                    </>}
                                    
                                    </div>
                                    {
                                        viewTerminals&&
                                        <div className="transportDetails-Terminals-item1b">
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                    </div>
                                    }
                                </div>
                               
                        </div> :null
                    }
                    
                </div>
                




            </div>
        </div>
    )
}

export default TransportCompanyDetailes