import motoIcon from "../../images/busIcon.png"
import { useDispatch, useSelector } from 'react-redux'
import { bookTicket, getAvailableSeats } from '../../../../actions/authActions'
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"


const SearchTrips = () => {
  const dispatch = useDispatch()
  const filteredTrips=JSON.parse(localStorage.getItem('filteredTrip'))
  const ticketId = useSelector((state)=>state.auth.bookedTicket?.ticket?._id || '')
  const token = useSelector((state)=>state.auth?.token)
  const navigate = useNavigate()
  const searchInputStore = JSON.parse(localStorage.getItem("searchInput"));

const handleSearchDetails = (filteredTrip) => {
  
  const updatedSearchInput = {
    from: filteredTrip.from || '',
    to: filteredTrip.to || '',
    from_time: filteredTrip.from_time || '',
    to_time: filteredTrip.to_time || '',
    date: searchInputStore?.date || new Date().toISOString().split('T')[0], 
    tripType: filteredTrip.tripType || '',
    adult: searchInputStore.adult,
    children: searchInputStore.children,
  };
  
    if (updatedSearchInput) {
      dispatch(bookTicket(updatedSearchInput, filteredTrip.transCompany?._id || "", token));
    }
  };

  useEffect(() => {
    if (ticketId) {
      navigate('/selectseat');
      dispatch(getAvailableSeats(ticketId, token));
    }
  }, [ticketId, dispatch, navigate, token]);



  return (
    <div className='travellerSide-homepage-container'>
            <div className='search-result-holder'>
        { filteredTrips.length > 0 ? 
          filteredTrips.map((filteredTrip)=>(
            <div className="search-result-item"onClick={() => handleSearchDetails(filteredTrip)} key={filteredTrip._id}>
            <div className="search-result-item-top">
              <img src={filteredTrip.transCompany?.companyPicture?.url} alt="companyLogo" />
              <h5>{filteredTrip.transCompany?.companyName}</h5>
            </div>
            <div className="search-result-item-mid">
              <div className="search-result-item-midA">
                    <h5>{filteredTrip.from}</h5>
                    <h4>{filteredTrip.from_time}</h4>
              </div>
              <div className="search-result-item-midB">
                <div className="newDot"></div>
                <img src={motoIcon} alt="" />
                <div className="newDot"></div>
              </div>
              <div className="search-result-item-midC">
              <h4>{filteredTrip.to_time}</h4>
              <h5>{filteredTrip.to}</h5>
              </div>
            </div>
            <div className="search-result-item-btm">
              <h6>16 seats </h6> <p>{filteredTrip.amount}/person</p>
            </div>
            </div>
          )) : <p> Trip with the searched route is not available now</p>

        }
          
        </div>
    </div>
  )
}

export default SearchTrips