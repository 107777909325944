import React, { useEffect, useState } from 'react'
import "./driverDashboard.css"
import { SlOptions } from "react-icons/sl";
import profilePics from "../../../TravellerSide/images/profile.jpg"
import { IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { IoMdHome } from "react-icons/io"; 
import { FaArrowRight } from "react-icons/fa";
import motoLogo from "../../../TravellerSide/images/motoLogo.png"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { driverTrips } from '../../../../actions/authActions';


const DriverDashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('driverId');
  const driverEmail = localStorage.getItem('driverEmail')
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const driverTripsData = useSelector((state) => state.auth.driverTrips?.data || '');

 useEffect(() => {
    if (userId && driverEmail) {
      dispatch(driverTrips({ email: driverEmail }, token));
    }
  }, [dispatch, userId, driverEmail, token]);

  const haandleDrivertripDetails = (tripsData) =>{
    navigate('/drivertripdetails', { state: { tripId: tripsData.tripId } })
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='driverdashboard-mainContainer'>
      <div className="driverdashboard-main">
          <div className="driverdashboard-main-left">
            <div className="driverdashboard-main-left-sec1">
               <SlOptions />
            </div>
            <div className="driverdashboard-main-left-sec2">
              <div className="driverdashboard-main-left-sec2a">
                <img src={profilePics} alt="" />
              </div>
              <div className="driverdashboard-main-left-sec2b">
                  <h4>Mr. Gabriel Doe</h4>
                  <h5>Driver’s ID: GIGD-223909 </h5>
              </div>
            </div>
            <div className="driverdashboard-main-left-sec3">
              <h5>Contact Details:</h5>
              <div className="driverdashboard-main-left-sec3a">
              <IoCallSharp color='red' /> <h6>+234-900-123-4236</h6>
              </div>
              <div className="driverdashboard-main-left-sec3a">
              <IoMdMail color='red' /> <h6>gabrieldoe098@gmail.com</h6>
              </div>
              <div className="driverdashboard-main-left-sec3a">
              <IoMdHome color='red' /> <h6>Block 25, windinburg street, Ikeja, Lagos</h6>
              </div>
            </div>
          </div>
          <div className="driverdashboard-main-right">
              <div className="driverdashboard-main-right-top">
                <h2>Today’s Trips</h2>
                {driverTripsData.length > 0 ? (
                    driverTripsData.map((tripsData)=>(
                      <div key={tripsData.tripId} className="driverdashboard-main-right-top-item" onClick={() => haandleDrivertripDetails(tripsData)}>
                      <img src={motoLogo} alt="" />
                      <div className="driverdashboard-main-right-top-itema">
                            <div className="driverdashboard-main-right-top-itema1">
                              <h5>{tripsData.from}</h5>
                              <FaArrowRight color='red' size={15} />
                              <h5>{tripsData.to}</h5>
                            </div>
                            <h5 className='driverdashboard-main-right-top-itema-h5'>{tripsData.depDate}</h5>
                      </div>
                      <h5>{tripsData.vehicle[0]?.serialNo}</h5>
                      <h5 className='h5-hidden'>{tripsData.depTime}</h5>
                </div>
                  ))
                ):(<p>No trips available at the moment.</p>)}
                
                
                
              </div>
              <div className="driverdashboard-main-right-top">
                <h2>Upcoming Trips</h2>
                
                <div className="driverdashboard-main-right-top-item">
                      <img src={motoLogo} alt="" />
                      <div className="driverdashboard-main-right-top-itema">
                            <div className="driverdashboard-main-right-top-itema1">
                              <h5>ABJ</h5>
                              <FaArrowRight color='red' size={15} />
                              <h5>LAG</h5>
                            </div>
                            <h5 className='driverdashboard-main-right-top-itema-h5'>20 April 2024</h5>
                      </div>
                      <h5>GIGM286GD</h5>
                      <h5 className='h5-hidden'>6:00AM - 9:30PM</h5>
                </div>
                <div className="driverdashboard-main-right-top-item">
                      <img src={motoLogo} alt="" />
                      <div className="driverdashboard-main-right-top-itema">
                            <div className="driverdashboard-main-right-top-itema1">
                              <h5>ABJ</h5>
                              <FaArrowRight color='red' size={15} />
                              <h5>LAG</h5>
                            </div>
                            <h5 className='driverdashboard-main-right-top-itema-h5'>20 April 2024</h5>
                      </div>
                      <h5>GIGM286GD</h5>
                      <h5 className='h5-hidden'>6:00AM - 9:30PM</h5>
                </div>
                
              </div>
          </div>
      </div>
    </div>
  )
}

export default DriverDashboard