import React from 'react'
import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg"
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg"
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg"
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg"
import driverImg from '../../images/driverImg.png'
import { viewAllDrivers } from '../../../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { CiSearch } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";

const CompanyDrivers = () => {
  const dispatch = useDispatch();
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const drivers = useSelector((state) => state.auth.driver?.data || []);

  useEffect(() => {
    if (userId && token) {
      dispatch(viewAllDrivers(userId, token))
        .catch((error) => {
          console.error('Failed to fetch drivers:', error);
        });

    } else {
      console.warn('userId or token not found');
    }
  }, [dispatch, userId, token]);

  return (
    <div className='companyVehicles-mainContainer'>
        <div className="company-dash-toplayer"></div>
      <>
       <h2>Drivers</h2>
       <div className='company-tickets-maincontainer'>
     <div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
 
</div>
</div>
<div className='driverList-label-holder'>
<div className='vehicle-image-label'></div>
<p className='vehicle-label'>Driver's  ID</p>
<p className='vehicle-label'>Full Name</p>
<p className='vehicle-label'>Date Joined</p>
<div className="vehicle-menuHolder"></div>


</div>
{drivers.map((driver)=>(
  <div className='vehicle-list-holder'>
  <div className='vehicle-list-item' key={driver.id}>
     <div className='vehicle-image2'>
         <img src={driverImg} alt="" />
     </div>
     <p className='vehicle-label'>{driver.driverID} </p>
     <p className='vehicle-label'>{driver.firstName} {driver.lastName}</p>
     <p className='vehicle-label'>04 Oct 2023</p>
   <div className='vehicle-menuHolder'>
   <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
   </div>
  </div>
  
  </div>
))}


<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
 <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
 <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
 <p>1</p>
</div>
<div className='clientManagement-history-item2'>
 <p>2</p>
</div>
<div className='clientManagement-history-item2'>
 <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
 <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
     </div>
     <div className='company-tickets-maincontainer-mobile'>
                       <div className="company-tickets-maincontainer-mobile-sec1">
                         <div className="company-tickets-maincontainer-mobile-sec1a">
                             <CiSearch />
                             <input type="text" placeholder='search' />
                         </div>
                         <div className="company-tickets-maincontainer-mobile-sec1b">
                               <label htmlFor="">Latest</label> <FaAngleDown />
                         </div>
                       </div>
                       <div className='company-tickets-maincontainer-mobile-sec2'>
                       { drivers ?
                         drivers.map((driver)=>(
                           
                                 <div className="company-tickets-maincontainer-mobile-sec2a" key={driver.id}>
                                   
                                   <div className='company-tickets-maincontainer-mobile-sec2a2'>
                                       <img src={driverImg} alt="" />
                                   </div>
                                   <div className="company-tickets-maincontainer-mobile-sec2a1">
                                     <p>Driver's ID</p>
                                     <p>{driver.driverID}</p>
                                   </div>
                                   <div className="company-tickets-maincontainer-mobile-sec2a1">
                                     <p>Fullname</p>
                                     <p>{driver.firstName} {driver.lastName}</p>
                                   </div>
                                   <div className="company-tickets-maincontainer-mobile-sec2a1">
                                     <p>Date joined</p>
                                     <p>{driver?.date || "NA"}</p>
                                   </div>
                                 </div>
                       
                         )) : <p style={{padding:'1rem'}}>
                           No vehicles added yet, kindly add a vehicle now!!!
                         </p>
                       }
                       </div>
                       
                 </div>
       </>
    </div>
  )
}

export default CompanyDrivers