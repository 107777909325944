import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadProfilePicture, updateCompanyData } from '../../../../actions/authActions'
import "./UserProfile.css"
import phoneIcon from "../../images/phone.svg"
import emailIcon from "../../images/email.svg"
import FbIcon from "../../images/facebook.svg"
import TwtIcon from "../../images/twitter.svg"
import IgIcon from "../../images/instagram.svg"



const UserProfile = () => {
  const [loading, setLoading] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const dispatch = useDispatch();
  const { imageUrl, error } = useSelector((state) => state.auth);
  const userData = useSelector((state)=>state.auth?.userData || [])
 

  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const companyLogo = userData.data?.url || []
  

  const [formData, setFormData]= useState({
    companyName: userData?.companyName || '',
    companyAcronym: userData?.companyAcronym || '',
    phoneNumber: userData?.phoneNumber || '',
    address: userData?.address || '',
    aboutUs: userData?.aboutUs || '',
    facebook: userData?.facebookHandle || '',
    twitter: userData?.twitterHandle || '',
    instagram: userData?.instagramHandle || '',
  })

    const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleImageChange = (event) => {
    const companyPicture = event.target.files[0];
    if (companyPicture) {
        dispatch(uploadProfilePicture(companyPicture, userId, token));
    }
};

const handleSubmit = (e) => {
  e.preventDefault();
  setLoading(true);

  const updatedData = new FormData();

  // Append all form data to FormData object
  Object.keys(formData).forEach((key) => {
      updatedData.append(key, formData[key]);
  });

  dispatch(updateCompanyData(updatedData, userId, token))  // updateCompanyData to match the company-specific action
    .then((response) => {
      // Assuming your response has the structure you provided
      if (response && response.message) {
        alert(response.message); // Alert the response message
      }
      setEditProfile(false); // Set editProfile to false upon success
    })
    .catch((error) => {
      console.error("Error updating data:", error);
      alert("Failed to update user data. Please try again."); // Optional: alert for failure
    })
    .finally(() => {
      setLoading(false); // Stop loading regardless of success or failure
    });
};


  const handleEditProfile = ()=>{
    setEditProfile(true)
  }
  

  return (
    <form className='companyside-main-content' onSubmit={handleSubmit}>
    {editProfile ? 
    
    <>
    <div className='company-dash-toplayer'> </div>
    <div className='company-editProfile-topsection'>
      <h4>Logo</h4>
      <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                disabled={loading}
            />
            {loading && <p>Uploading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {imageUrl && <img src={imageUrl} alt="Profile" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />}
        
      {/* <img src={user2} alt="" /> */}
      <div className='company-editprofile-topsection-inputfield'>
      <div className='company-editprofile-topsection-inputfield-item'>
        <label> Company Name</label>
        <input type="text" name='companyName' value={formData.companyName} onChange={handleInputChange} />
      </div>
      </div>
      <div className='company-editprofile-topsection-inputfield2'>
      <div className='company-editprofile-topsection-inputfield-item'>
        <label>Phone Number</label>
        <input type="text" name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} />
      </div>
      <div className='company-editprofile-topsection-inputfield-item'>
        <label> Email Address</label>
        <input type="text" name='email' value={formData.email} onChange={handleInputChange}/>
      </div>
      </div>
    </div>
    <div className='company-editProfile-secondSection'>
      <h4>About Us</h4>
      <input type="text" placeholder='Type something...' name='aboutUs' value={formData.aboutUs} onChange={handleInputChange}/>
    </div>
    <div className='company-editProfile-lastSection'>
      <div className='company-editprofile-lasction-item'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>facebook</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" name='facebook' value={formData.facebook} onChange={handleInputChange}/><img src={FbIcon} alt="" /></div>
      </div>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Twitter</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" name='twitter' value={formData.twitter} onChange={handleInputChange} /><img src={TwtIcon} alt="" /></div>
      </div>
      </div>
      <div className='company-editprofile-lasction-item'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Instagram</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" name='instagramo' value={formData.instagram} onChange={handleInputChange} /><img src={IgIcon} alt="" /></div>
      </div>
      </div>
    </div>
    <button className='saveBtn' type='submit'>{loading ? 'saving...' : 'save'}</button>


    </>:
    
    <>
    <div className='company-dash-toplayer'> 
      <button onClick={handleEditProfile}>Edit Profile</button>
      </div>
  <div className='companyside-main-container'>
    <div className='companydash-userProfile-section1'>
      <div className='companydash-userProfile-section1Main'>
        <div className='companydash-userProfile-section1Main-row1'>
          <img className='company-profile-logo' src={companyLogo} alt="logo" />
          <h3>{userData?.data?.companyName || 'company'}</h3>
        </div>
        <h4>Contact Details</h4>
        <div className='companySide-userProfile-contactHolder'>
          <img src={phoneIcon} alt="" />
          <label >{userData?.data?.phoneNumber || 'unavailabe'}</label>
        </div>
        <div className='companySide-userProfile-contactHolder'>
          <img src={emailIcon} alt="" />
          <label >{userData?.data?.email || 'unavailable'}</label>
        </div>
      </div>
    </div>
    <div className='companydash-userProfile-section1'>
      <div className='companydash-userprofile-aboutUsHolder'>
        <h3>About Us:</h3>
        <div className='companySide-aboutUS-content'>{userData?.data?.aboutUs || 'unavailable'}</div>
      </div>
    </div>
    <div className='companydash-userProfile-section3'>
      <div className='companydash-userprofile-lastsection-header'>
        Socials
      </div>
      <div className='companydash-userprofile-lastsection-item-main'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>facebook</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" /><img src={FbIcon} alt="" /></div>
      </div>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Twitter</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" /><img src={TwtIcon} alt="" /></div>
      </div>
      </div>
      <div className='companydash-userprofile-lastsection-item-main'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Instagram</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" /><img src={IgIcon} alt="" /></div>
      </div>
      
      </div>
    </div>
  </div>
    </>


    
    }
  
  </form>
  )
}

export default UserProfile