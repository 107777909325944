import React, { useState } from "react"
import "./travellerTicketDetails.css"
import motoLogo from "../../images/motoLogo.png"
import busIcon from "../../images/busIcon.png"
import busIcon2 from "../../images/Vector.png"
import { FaArrowRight } from "react-icons/fa";
import { useLocation } from "react-router-dom"

const TravellerTicketDetails = ()=>{
    const [showDetails, setShowDetail] = useState(true)
    const location = useLocation()

    const handleSHowDetails = ()=>{
        setShowDetail(!showDetails)
    }

    const ticket = location.state?.ticket; // Retrieve the ticket data

      const calculateDuration = (departureTime, arrivalTime) => {
        if (!departureTime || !arrivalTime) return "Unknown Duration";
    
        // Convert time strings to Date objects
        const depTime = new Date(`2024-01-01 ${departureTime}`);
        const arrTime = new Date(`2024-01-01 ${arrivalTime}`);
    
        // Calculate the difference in milliseconds
        let diffMs = arrTime - depTime;
        
        // If arrival time is earlier than departure, assume it's the next day
        if (diffMs < 0) {
            diffMs += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
        }
    
        // Convert milliseconds to hours and minutes
        const hours = Math.floor(diffMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    
        return `${hours} hrs ${minutes} mins`;
    };

    return (
        <div className="travellerTickets-main">
         <div className="travellerTicketsDetails-main">
         <div className="travellerTicketDetails-container" onClick={handleSHowDetails}>
            <div className="travellerTicket-details-item1">
                <div className="travellerTicket-details-item1a">
                    <img style={{width:'50px', height:'50px'}} src={ticket?.transCompany?.companyPicture?.url} alt="" />
                    <div className="travellerTicket-details-itBem1a1">
                            <h4>{ticket?.transCompany?.companyName}</h4>
                            <p>{ticket?.ticketId}</p>
                    </div>
                </div>
                <div className="travellerTicket-details-item1b">
                    <div className="travellerTicketDetails-item1b1">
                        <div className="travellerTicketDetails-item1b1a">
                                <img src={busIcon} alt="" />
                                <h5>{ticket?.to_time}</h5>
                                <p>{ticket?.from}</p>
                        </div>
                        <div className="travellerTicketDetails-item1b1a">
                                <FaArrowRight color="red"/>
                                <p>{ticket?.to}</p>
                                <h5>{ticket?.from_time}</h5>
                        </div>
                        <div className="travellerTicketDetails-item1b1c">
                                
                                <p>{calculateDuration(ticket?.to_time, ticket?.from_time)}</p>
                                <p>{ticket?.date}</p>       
                        </div>
                    </div>
                </div>
            </div>
            
           </div>
           {
            showDetails&& 
            <div className="travellerTicket-details-item1-details">
                <div className="travellerTicket-details-item1-details-item1">
                    <div className="travellerTicket-details-item1-details-item1-top">
                        <div className="travellerTicket-details-item1-details-item1-topa">
                            <div className="travellerTicket-details-item1-details-item1-topa1">
                                <p>Departure</p>
                                <p>{calculateDuration(ticket?.to_time, ticket?.from_time)}</p>
                            </div>
                            <div className="travellerTicket-details-item1-details-item1-topa2">
                                <p>{ticket.from}</p>
                                <img src={busIcon} alt="" />
                                <p>{ticket.to}</p>
                            </div>
                        </div>
                        <div className="travellerTicket-details-item1-details-item1-topb">
                            <div className="travellerTicket-details-item1-details-item1-topa1">
                                <p>Arrival</p>
                                <p>{calculateDuration(ticket?.to_time, ticket?.from_time)}</p>
                            </div>
                            <div className="travellerTicket-details-item1-details-item1-topa2">
                                <p>{ticket.from}</p>
                                <img src={busIcon} alt="" />
                                <p>{ticket.to}</p>
                            </div>
                        </div>
                    </div>
                    <div className="travellerTicket-details-item1-details-item1-btm">
                        <div className="travellerTicket-details-item1-details-item1-btm-item1">
                            <div className="travellerTicket-details-item1-details-item1-btm-item1a">
                                <img src={busIcon2} alt="" />
                                <div className="locationDot"></div>
                            </div>
                            <h5>Departs Terminal: {ticket.from}</h5>
                        </div>
                        <div className="travellerTicket-details-item1-details-item1-btm-item2">
                            <div className="travellerTicket-details-item1-details-item1-btm-item1a">
                                <div className="locationDot2"></div>
                            </div>
                            <h6>Rest Stop</h6>
                        </div>
                        <div className="travellerTicket-details-item1-details-item1-btm-item1">
                            <div className="travellerTicket-details-item1-details-item1-btm-item1a">
                                
                                <div className="locationDot"></div>
                                <img src={busIcon2} alt="" />
                            </div>
                            <h5>Arrival Terminal: {ticket.to}</h5>
                        </div>
                    </div>
                </div>
                <div className="travellerTicket-details-item1-details-item2">
                <div className="travellerTicket-details-item1-details-item1-top2">
                           <p> Fare summary</p>
                        </div>
                        <div className="ravellerTicket-details-item1-details-item1-btm2">
                            <div className="ravellerTicket-details-item1-details-item1-btm2-item">
                            <label htmlFor="">Fare Summary</label>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-itema">
                            <h4>Adult X1</h4> <h4># {ticket.amount}</h4>
                            </div>
                            </div>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-item">
                            
                            <div className="ravellerTicket-details-item1-details-item1-btm2-itema">
                            <label htmlFor="">Tax</label> <h4>#{ticket?.tax || 0.00}</h4>
                            </div>
                            </div>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-item">
                            <label htmlFor="">Total</label>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-itema">
                            <p>1 traveller</p> <h4 className="ravellerTicket-details-item1-details-item1-btm2-itema-h4"># {ticket.amount}</h4>
                            </div>
                            </div>
                        </div>
                </div>
            </div>
           }
         </div>
        </div>
    );
};

 export  default TravellerTicketDetails;