import React, { useState } from 'react'
import "./Login.css"
import logo from "./images/logo.svg"
import search from "./images/search.svg"
import copyright from "./images/Copyright.svg"
import { useDispatch, useSelector } from 'react-redux'
import { adminLogin } from '../../../../actions/authActions'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email:'',
    password:''
  })
  const { message, error } = useSelector((state) => state.auth);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    if (!formData.email) {
      Swal.fire('Error', 'Email is required', 'error');
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      Swal.fire('Error', 'Enter a valid email', 'error');
      return false;
    }
    if (!formData.password) {
      Swal.fire('Error', 'Password is required', 'error');
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      dispatch(adminLogin(formData))
        .then(() => {
          Swal.fire('Success', message || 'Login successful!', 'success');
          navigate('/dashboard'); // Redirect to the admin dashboard after successful login
        })
        .catch(() => {
          Swal.fire('Error', error || 'Login failed. Please check your credentials.', 'error');
          navigate("/adminlogin");
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <div className='login-mainContainer'>
      <div className='login-Header'>
        <div className='login-header-logo-holder'>
          <img className='login-header-logo' src={logo} alt="" />
        </div>
        <div className='login-btn-holder'>
          <button className='login-btn-login'>Log In</button>
          {/* <button className='login-btn-signup' onClick={()=>navigate('adminsignup')}>Sign Up</button> */}
          <img className='login-header-search' src={search} alt="" />
        </div>
      </div>
        <div className='login-container'>
              <div className='login-container-leftSection'>
                <img src={logo} alt="" />
                <h2>Admin Sign In</h2>
              </div>
              <form className='login-container-rightSection' onSubmit={handleSubmit}>
                <div className='login-container-input'>
                  <label>Enter Username</label>
                  <input type="text" placeholder='Username' name='email' value={formData.email} onChange={handleChange} />
                </div>
                <div  className='login-container-input'> 
                  <label >Enter Password</label>
                  <input type="password" placeholder='Password' name='password' value={formData.password} onChange={handleChange}/>
                </div>
                <div className='login-container-checkbox'>
                  <div><input type="checkbox" /> <label >Remember me</label></div>
                  {/* <span >Forgot Password?</span> */}
                </div>
                <button disabled={loading}>{loading? 'signing in...' : 'Sign In'}</button>
                {/* <div className='login-container-createaccount'><p>Need an account? <span onClick={()=>navigate('adminsignup')}> Create an Account</span></p></div> */}
                 </form>
                 
        </div>
        <div className='login-footer'>
          <img src={copyright} alt="" /><p>Copyright VadTrans 2024. All Rights Reserved</p>
        </div>
      
    </div>
  )
}

export default Login
