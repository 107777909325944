import React, { useEffect, useState } from 'react'
import "./CompanyLogin.css"
import { NavLink, useNavigate } from 'react-router-dom'
import { clearMessages, login } from '../../../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from "sweetalert2"
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa6";


const CompanyLogin = () => {
  const [visible, setVisible] = useState(false)
const [formData, setFormData] = useState({
  email:"",
  password:"",
})
const [loading, setLoading] = useState(false)
const navigate = useNavigate();
const dispatch = useDispatch();
const {token, userId, error, message} = useSelector((state)=>state.auth)

const handleChange = (e)=>{
  setFormData({
    ...formData,
    [e.target.name] : e.target.value,
  });
};


const validateForm = () =>{
  if(!formData.email){
    Swal.fire('Error', 'email is required', 'error');
    return false;
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(formData.email)) {
    Swal.fire('Error', 'Enter a valid email', 'error');
    return false;
  }
  if(!formData.password){
    Swal.fire('Error', "Password is required", 'error')
    return false
  }
  return true
}

const handleCOmpanyLogin= (e)=>{
  e.preventDefault();
  if(validateForm()){
    setLoading(true)
    dispatch(login(formData, 'company'))
      .finally(()=>setLoading(false));
  }
};

useEffect(() => {
    if (token && message) {
      dispatch(clearMessages()); // Clear message after displaying
        navigate("/companydashboard")
    } else if (error) {
      Swal.fire('Error', error || 'An error occurred').then(() => {
        dispatch(clearMessages()); // Clear error after displaying
      });
    }
  }, [token, userId, message, error, dispatch, navigate]);


  return (
   <form onSubmit={handleCOmpanyLogin}>
     <div className='comapnyLogin-container'>
    <div className='company-login-content'>
      <h2>Sign In</h2>
      <h5>Access your account with your email address and passwords.</h5>
      <div className='login-email-holder' >
        <label>Email</label>
        <input type="email" placeholder='Email' name='email' value={formData.email} onChange={handleChange} />
      </div>
      <div className='login-email-holder'>
        <label> Password</label>
        <div className="password-inputHolder3">
        <input className='password-inputHolder3a' type={visible ? "text" : 'password'} name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
              {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
        </div>
         </div>
      <NavLink to='/companypasswordreset'><span>Forgot password?</span> </NavLink>
      
      <button type='submit' disabled={loading}> {loading ? 'Logging in...' : 'Login'}</button>
     
    </div>
  </div>
   </form>
  )
}

export default CompanyLogin
