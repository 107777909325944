import React from 'react';
import "./travellersTicket.css"
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineSearch } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import { FaNairaSign } from "react-icons/fa6";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TravellersTicket = () => {
  const navigate = useNavigate()
  const tickets = useSelector((state)=>state.auth?.ticket?.data || [])
  
  const handleTicketDetailsBtn = (ticket) => {
    navigate(`/travellerticketticketinfo`, { state: { ticket } }); // Pass the whole ticket as state
  };

  const goBack = () => {
    window.history.back();
  };



  return (
    <div className='TravellerTicket-holder'>
      <div className="TravellerTicket-holder-web">
      <div className="editProfile-top">
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack}/>
          <h3>Tickets</h3>
        </div>
        <div className="travellerTicket-sec1">
            <select name="" id="">
              <option value="" >Latest</option>
              <option value="">by Price</option>
              <option value="">by location</option>
            </select>
            <div className="traveller-sec1-input">
            <MdOutlineSearch />
            <input type="text" placeholder='search' />
            </div>
        </div>
        <div className="travellerTicket-sec2-holder">
              <div className="travellerTicket-sec2-top">
               <p className="travellerTicket-sec2-topItems">Ticket ID</p>
               <p className="travellerTicket-sec2-topItems">Transport Company</p>
               <p className="travellerTicket-sec2-topItems">Destination</p>
               <p className="travellerTicket-sec2-topItems">Trip Type</p>
               <p className="travellerTicket-sec2-topItems">Amount</p>
               <p className="travellerTicket-sec2-topItems">Date</p>
              </div>
              <>
              {
                tickets.map((ticket)=>(
                  <div className="travellerTicket-item" key={ticket._id} >
              <div className="p-ticketID">{ticket.ticketId}</div>
                <div className="p-TransportCompany">{ticket.transCompany?.companyName}</div>
                <div className="p-destination">
                  <div className='destination-item1'>
                  <p>{ticket.from}</p>
                  <FaArrowRight color='red'/>
                  <p>{ticket.to}</p>
                  </div>
                  
                </div>
                <div className="p-TripType">{ticket.tripType}</div>
                <div className="p-amount"><FaNairaSign color='red' /> <p style={{color:"red"}}>{ticket.amount}</p></div>
                <div className="p-date">
                    <div className='p-date-holder' onClick={() => handleTicketDetailsBtn(ticket)}>
                    <p >View</p><IoEyeOutline cursor="pointer"/>
                    </div>
                  <p>{ticket.date}</p>
                </div>
              </div>
                ))
              }
              
              </>
              
        </div>
        <div className="travellerTicket-navigation">
          <div className="navBack">
          <MdOutlineKeyboardArrowLeft />
          </div>
          <div className="navPageNumber-holder">
            <p>1</p>
          </div>
          <div className="navForward">
          <MdOutlineKeyboardArrowRight />
          </div>
        </div>
      </div>
      <div className="TravellerTicket-holder-mobile">
      <div className="editProfile-top">
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack}/>
          <h3>Tickets</h3>
        </div>
        <div className="TravellerTicket-holder-mobile-top">
        <select name="" id="">
              <option value="" >Latest</option>
              <option value="">by Price</option>
              <option value="">by location</option>
            </select>
            <div className="TravellerTicket-holder-mobile-top-input">
            <MdOutlineSearch />
            <input type="text" placeholder='search' />
            </div>
        </div>
        <div className="TravellerTicket-holder-mobile-itemHolder">
          {tickets.map((ticket)=>(
            <div className="TravellerTicket-holder-mobile-items" key={ticket._id}>
                <div className="TravellerTicket-holder-mobile-item1">
                  <div className="TravellerTicket-holder-mobile-item1a">
                    <p>Ticket ID:</p>
                    <h3>{ticket?.ticketId}</h3>
                  </div>
                  <div className="TravellerTicket-holder-mobile-item1a">
                    <p>Transport Company:</p>
                    <h3>{ticket?.transCompany?.companyName}</h3>
                  </div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Destination</p>
                  <div className='destination-item1'>
                  <p>{ticket?.from}</p>
                  <FaArrowRight color='red'/>
                  <p>{ticket?.to}</p>
                  </div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Trip Type</p>
                  <p>{ticket.tripType}</p>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Amount</p>
                  <div className="p-amount"><FaNairaSign color='red' /> <p style={{color:"red"}}>{ticket?.amount}</p></div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Date</p>
                  <p>{ticket?.date}</p>
                </div>
                <div className="TravellerTicket-holder-mobile-item3">
                          <p onClick={() => handleTicketDetailsBtn(ticket)} >View</p><IoEyeOutline cursor="pointer"/>
                </div>
          </div>
          ))}
          
        </div>
        <div className="travellerTicket-navigation">
          <div className="navBack">
          <MdOutlineKeyboardArrowLeft />
          </div>
          <div className="navPageNumber-holder">
            <p>1</p>
          </div>
          <div className="navForward">
          <MdOutlineKeyboardArrowRight />
          </div>
        </div>
      </div>

        
    </div>
  );
};

export default TravellersTicket;
