import React, { useEffect, useState } from 'react'
import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import { FaArrowRight } from "react-icons/fa";
import "./CompanyPayment.css"
import { useDispatch, useSelector } from 'react-redux'
import { companywithdrawal, updateAccountDetails, viewCompanyBal } from '../../../../actions/authActions'
import Swal from 'sweetalert2'
import Banks from './Banks.json'

const CompanyPayment = () => {
  const [withdrawal, setWithdrawal] = useState(false) 
  const [addAccount, setAddAccount] = useState(false)
  const {token} = useSelector((state)=>state.auth)
  const companyData = useSelector((state)=>state.auth?.userData?.data || [])
  const [amount, setAmount] = useState(0)
  const [loading, setLoading ] = useState(false)
  const savedBalance = localStorage.getItem('walletBalance');
  const dispatch = useDispatch()
  const banksInNigeria = Banks.data
  const [formData, setFormData] = useState({
    bankCode:'',
    accountName:'',
    accountNumber:'',
  })
const validateFormData = ()=>{
  if(!formData.bankCode){
    Swal.fire('Error', 'Bank Name is required', 'error')
    return false
  }
  if(!formData.accountNumber){
    Swal.fire('Error', 'Bank name is required,', 'error')
    return false
  }
  if(formData.accountNumber.length < 10 ||formData.accountNumber.length > 10 ){
    Swal.fire("Error", 'Account number must be 10 digits', 'error')
    return false
  }
  return true
}

const handleWithdrawal = async () => {
  if (!amount || amount > savedBalance) {
    Swal.fire('Error', 'Please enter an amount lower or equal to your available balance', 'error');
    return;
  }

  setLoading(true);
  try {
    // Dispatch the withdrawal action and wait for the response
    const response = await dispatch(companywithdrawal({ amount }, token));

    // Handle the API success response
    Swal.fire('Success', response.message || 'Withdrawal request submitted successfully.', 'success');
  } catch (error) {
    // Handle the API error response
    Swal.fire('Error', error.message || 'Something went wrong. Please try again.', 'error');
  } finally {
    setLoading(false);
  }
};



const handleAddAcctDetails = async () => {
  // Create a copy of formData with the updated accountName
  const updatedFormData = {
    ...formData,
    accountName: companyData.companyName || '',
  };

  // Update the state
  setFormData(updatedFormData);
  setLoading(true)
  // Validate the updated form data
  if (validateFormData(updatedFormData)) {
    try {
      // Dispatch the action and wait for the response
      const response = await dispatch(updateAccountDetails(updatedFormData, token));

      // Display success message
      Swal.fire('Success', response.message || 'Account details updated successfully.', 'success');
    } catch (error) {
      // Display error message
      Swal.fire('Error', error.message || 'Failed to update account details. Please try again.', 'error');
    }
  } else {
    Swal.fire('Error', 'Invalid form data. Please check your inputs.', 'error');
  } 
      setLoading(false)
  
};




  const handleWIthdrawalIsTrue = ()=>{
    setWithdrawal(true)
    setAddAccount(false)
  }
  const handleAddAcctIsTrue = ()=>{
    setWithdrawal(false)
    setAddAccount(true)
  }

  useEffect(()=>{
      dispatch(viewCompanyBal(token))
  },[dispatch, token])
  return (
    <div className='company-payment-container'>
      <>
      <div className="accountDashboard">
        <div className="accountDashboard-left">
          <h5>Available Balance</h5>
          <div className="balHolder">{savedBalance? savedBalance : 'N/A'}</div>
        </div>
        <div className="accountDashboard-right">
         
          {withdrawal ? <>
            <input type="number" placeholder='Enter Amount' value={amount} name='amount' onChange={(e)=>setAmount(e.target.value)}/>
            <button onClick={handleWithdrawal}>{loading? "processing...":"Withdraw"}</button>
          </>
          :addAccount? <>
            <input type="text" placeholder='Enter account number' value={formData.accountNumber} onChange={(e)=>setFormData((prevState)=>({...prevState, accountNumber:e.target.value}))}/>
            <select name="bank" onChange={(e) => setFormData({ ...formData, bankCode: e.target.value })}>
             <option value="">Select Bank</option>
              {banksInNigeria.map((bank) => (
              <option key={bank._id} value={bank.code}>
              {bank.name}
            </option>
           ))}
        </select>
            <button onClick={handleAddAcctDetails}>{loading? 'updating..' : "Add Account"}</button>
          </>:
          <>
          <button onClick={handleWIthdrawalIsTrue}>Withdraw</button>
          <button onClick={handleAddAcctIsTrue}>Update Account Details</button>
          </>
        }
        </div>
      </div>
      <h3>Payments</h3>
      <div className='company-tickets-maincontainer'>
      <div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
  
</div>
</div>
<div className='clientManagement-history-labels'>
<p>Payment ID</p>
<p>Paid by</p>
<p>Amount</p>
<p>Date</p>
<p>Status</p>

</div>
{/* <div className="payment-listHolder">

</div> */}
<div className='clientManagement-list-holder'>
<div className='clientManagement-list-item'>
<p>GIGM286GD</p>
<p>Jane Doe</p>
<p>#12,000</p>
<p>04 Oct 2023</p>
<p className='status-button'>Successful</p>
  <div className='clientManagement-list-item-img2-container'>
  <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
  </div>
</div>

</div>

<div className="payment-holder-items">
    <div className="payment-holder-items1">
      <p>Trip ID</p>
      <h4>GIGM286GD</h4>
    </div>
    <div className="payment-holder-items2">
      <p>Destination</p>
      <div className="payment-holder-items2a">
        <p>LAG</p>
        <FaArrowRight color='red' />
        <p>ABJ</p>
      </div>
    </div>
    <div className="payment-holder-items2">
      <p>Passenger</p>
      <p>Jane Doe</p>
    </div>
    <div className="payment-holder-items2">
      <p>Amount</p>
      <p style={{color:'red'}}># 12,000</p>
    </div>
    <div className="payment-holder-items2">
      <p>Date</p>
      <p>26/12/2024</p>
    </div>
    <div className="payment-holder-items3">
      <p>View more</p>

    </div>
</div>


{/* <div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
  <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
  <p>1</p>
</div>
<div className='clientManagement-history-item2'>
  <p>2</p>
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
  <img src={doubleArrowRight} alt="" />
</div>

</div> */}

</div>
      </div>
      
      
      </>
    </div>
  )
}

export default CompanyPayment
