import React, { useState } from 'react'
import "./ContactInfo.css"
import { useDispatch, useSelector } from 'react-redux'
import { updateAdminData } from '../../../../../../actions/authActions'
import DashboardHeader from '../../components/DashboardHeader'
import fb from "../../pages/dashContent/images/fb.svg"
import ig from "../../pages/dashContent/images/instagram.svg"
import linkin from "../../pages/dashContent/images/linkedinIn.svg"
import twitter from "../../pages/dashContent/images/twitter.svg"
import Footer from "../../components/Footer"
import Swal from 'sweetalert2'


const ContactInfo = () => {
const [loading, setLoading] = useState(false)
const dispatch = useDispatch();
const adminData = useSelector((state)=> state.auth.userData?.data || [])
const token = localStorage.getItem('token');
const [formData, setFormData] = useState({
     email: "",
    phoneNumber: "",
    address: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: ""

})

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });
};

const handleSubmit = (e) => {
  e.preventDefault();
    setLoading(true);
    dispatch(updateAdminData(formData, token))
      .then(() => {
        Swal.fire('Success', 'contact info added successfully', 'success');
      })
      .catch((error) => {
        Swal.fire('Error', 'failed to add contact details', 'error');
      })
      .finally(() => setLoading(false));
  
};
  return (
    <div className='contact-info-mainContainer'>
      <div className='contentHeader-container'>
        <DashboardHeader/>
      </div>
      <form className='contact-info-content' onSubmit={handleSubmit}>
        <h3>Contact Information</h3>
        <div className='contact-info-item'>
          <div className='contact-info-item-title'>Contact Information</div>
          <div className='contact-info-inputfill-holder'>
          <div className='contact-info-item-list'>
            <label >Phone number</label>
            <input type="text" placeholder={adminData?.phoneNumber || 'phoneNumber'}  name='phoneNumber' value={formData.phoneNumber} onChange={handleChange}/>
          </div>
          <div className='contact-info-item-list'>
            <label >Email</label>
            <input type="text" placeholder={adminData?.email || "email"} name='email' value={formData.email} onChange={handleChange}/>
          </div>
          </div>
        </div>
        <div className='contact-info-item'>
          <div className='contact-info-item-title'>Address & Map</div>
          <div className='contact-info-inputfill-holder'>
          <div className='contact-info-item-list2'>
            <label >Address</label>
            <input type="text" placeholder={adminData?.address || '46, Amos Olagboyin Street, Ikeja, Lagos'}  name='address' value={formData.address} onChange={handleChange}/>
          </div>
          
          </div>
        </div>
        <div className='contact-info-item3'>
          <div className='contact-info-item-title3'>Social Media Links</div>
          <div className='contact-info-socialMedia-item-holder'>
          <div className='contact-inf0-socialMedia-item'> 
            <label>Facebook</label>
            <div className='contact-info-socialMedia-input'>
              <input type="text" placeholder={adminData.facebook || ''}  name='facebook' value={formData.facebook} onChange={handleChange}/>
              <img src={fb} alt="facebook icon" />
            </div>
          </div>
          <div className='contact-inf0-socialMedia-item'> 
            <label>Twitter</label>
            <div className='contact-info-socialMedia-input'>
              <input type="text" placeholder={adminData?.twitter || ''} name='twitter' value={formData.twitter} onChange={handleChange} />
              <img src={twitter} alt="twitter icon" />
            </div>
          </div>
          </div>
          <div className='contact-info-socialMedia-item-holder'>
          <div className='contact-inf0-socialMedia-item'> 
            <label>Instagram</label>
            <div className='contact-info-socialMedia-input'>
              <input placeholder={adminData?.instagram || ''} type="text" name='instagram' value={formData.instagram} onChange={handleChange} />
              <img src={ig} alt="instagram icon" />
            </div>
          </div>
          <div className='contact-inf0-socialMedia-item'> 
            <label>LinkedIn</label>
            <div className='contact-info-socialMedia-input'>
              <input placeholder={adminData?.linkedin || ''} type="text" name='linkedin' value={formData.linkedin} onChange={handleChange}/>
              <img src={linkin} alt="LinkedIn icon" />
            </div>
          </div>
          </div>
          
        </div>
        <button type='submit'>{loading? "completing..." : 'Complete'}</button>
    
      </form>
      <div className='footer'>
          <Footer/>
      </div>
      
    </div>
  )
}

export default ContactInfo
