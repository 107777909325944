
import { useState } from 'react'
import "./Dashboard.css"
import "../../pages/Dashboard/components/Sidebar.css"
import logo from "./pages/dashContent/images/logo.svg"
import daashboard from "./pages/dashContent/images/home.svg"
import ticket from "./pages/dashContent/images/ticket.svg"
import tracking from "./pages/dashContent/images/tracking.svg"
import clientMem from "./pages/dashContent/images/client.svg"
import payment from "./pages/dashContent/images/payment.svg"
import emailNot from "./pages/dashContent/images/email.svg"
import settingz from "./pages/dashContent/images/settings.svg"
import settingsDropdown from "./pages/dashContent/images/angleRight.svg"


import DashContent from './pages/dashContent/DashContent'
import ContactInfo from './pages/contactInfo/ContactInfo'
import Payment from './pages/payments/Payment'
import Faqs from './pages/Faqs/Faqs'
import Tracking from "./pages/Tracking/Tracking"
import ClientManagement from "./pages/clientManagement/ClientManagement"
import EmailNotification from "./pages/EmailNotfication/EmailNotification"
import Ticket from './pages/Tickets/Ticket'








const Dashboard = () => {

  const [dashContent, setDashContent]=useState(true)
  const [contactInfo, setContatInfo] = useState(false)
  const [payments, setPayments] = useState(false)
  const [faqs, setFaqs] = useState(false)
  const [trackings, setTrackings] = useState(false)
  const [clientManagement, setClientManagement] = useState(false)
  const [emailNotification, setEmailNotification] = useState(false)
  const [tickets, setTickets] = useState(false)

  
  
const handleDashboard = ()=>{
  setDashContent(true)
  setContatInfo(false)
  setPayments(false)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(false)
}

const handleContactInfo = ()=>{
  setDashContent(false)
  setContatInfo(true)
  setPayments(false)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(false)
}

const handlePayments =()=>{
  setDashContent(false)
  setContatInfo(false)
  setPayments(true)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(false)
}

const handleFaqs = ()=>{
  setDashContent(false)
  setContatInfo(false)
  setPayments(false)
  setFaqs(true)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(false)
}

const handleTracking = ()=>{
  setDashContent(false)
  setContatInfo(false)
  setPayments(false)
  setFaqs(false)
  setTrackings(true)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(false)
}

const handleClientManagement =()=>{
  setDashContent(false)
  setContatInfo(false)
  setPayments(false)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(true)
  setEmailNotification(false)
  setTickets(false)
}

const handleEmailNotification =()=>{
  setDashContent(false)
  setContatInfo(false)
  setPayments(false)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(true)
  setTickets(false)
}

const handleTickets =()=>{
  setDashContent(false)
  setContatInfo(false)
  setPayments(false)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(true)
}

  const [settingsDrop, setSettingsDrop]=useState(false)

const handleSettingsDrop=()=>{
  setSettingsDrop(!settingsDrop)
  setDashContent(false)
  setContatInfo(true)
  setPayments(false)
  setFaqs(false)
  setTrackings(false)
  setClientManagement(false)
  setEmailNotification(false)
  setTickets(false)
  
}



  return (
    <div className='dashboard-main-container'>
      <div className='dashboard-sidebar-holder'>
        {/* <Sidebar/> */}
        <div className='sidebar-main'>
     <div className='sidebar-main2'>
      <img src={logo} alt="" />
      <div  className= {`item1 ${dashContent ? "sidebar-menu-list-active" : "sidebar-menu-list"} `} onClick={handleDashboard}>
        <img src={daashboard} alt="" />
        <p>Dashboard</p>
      </div>
      <div  className={`item1 ${tickets ? "sidebar-menu-list-active" : "sidebar-menu-list"} `}  onClick={handleTickets}>
        <img src={ticket} alt="" />
        <p>Tickets</p>
      </div>
      <div  className={`item1 ${trackings ? "sidebar-menu-list-active ": "sidebar-menu-list"} `}  onClick={handleTracking}>
        <img src={tracking} alt="" />
        <p>Tracking</p>
      </div>
      <div  className={`item1 ${clientManagement ? "sidebar-menu-list-active" : "sidebar-menu-list"} `}  onClick={handleClientManagement}>
        <img src={clientMem} alt="" />
        <p>Client Membership</p>
      </div>
      <div  className={`item1 ${payments ? "sidebar-menu-list-active" : "sidebar-menu-list"} `}  onClick={handlePayments}>
        <img src={payment} alt="" />
        <p>Payments</p>
      </div>
      <div className={`item1 ${emailNotification ? "sidebar-menu-list-active" : "sidebar-menu-list"} `}  onClick={handleEmailNotification} >
        <img src={emailNot} alt="" />
        <p>Email Notification</p>
      </div>
      <div  className={`item1 ${contactInfo || faqs? "sidebar-menu-list-active ": "sidebar-menu-list"} `}  onClick={handleSettingsDrop}>
        <img src={settingz} alt="" />
        <p>Setting</p>
        <div className='settings-arrowDown' >
          <img src={settingsDropdown} alt="" />
        </div>
      </div>
    </div>
    <div className='settings-dropdown'>
    {settingsDrop && 
    (
      <div className='settings-dropdown2'>
      <div onClick={handleContactInfo} className={`item1 ${contactInfo? "settings-dropdown-items-active ": "settings-dropdown-items"}`}>
        <div className='dot'> </div> <p>Contact Information</p>
      </div>
      <div onClick={handleFaqs} className={`item1 ${faqs? "settings-dropdown-items-active ": "settings-dropdown-items"}`}>
        <div className='dot'> </div> <p>FAQs</p>
      </div>
    </div>
    )
      

    }
    </div>
   
   
   </div>
      </div>
      <div className='dashboard-content-holder'>
        {dashContent?(
        <DashContent/>
        ):contactInfo?(
          <ContactInfo/>
        ):payments?(
        <Payment/>
        ):faqs?(
          <Faqs/>
        ):trackings?(
          <Tracking/>
        ): clientManagement?(
          <ClientManagement/>
        ):emailNotification?(
          <EmailNotification/>
        ):tickets?(
          <Ticket/>
        ):null
        }
      </div>
    </div>
  )
}

export default Dashboard
