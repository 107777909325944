import React, { useEffect, useState } from 'react';
import "./signup.css";
import loginImage1 from "../../../images/panaLogin.svg";
import { clearMessages, travelerSignup } from '../../../../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa6";

const SignupTraveller = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: ""
    });
    const { error, message } = useSelector((state) => state.auth);
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        if (!formData.email) {
            Swal.fire('Error', 'Email is required', 'error');
            return false;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            Swal.fire('Error', 'Enter a valid email', 'error');
            return false;
        }
        if (!formData.password) {
            Swal.fire('Error', 'Password is required', 'error');
            return false;
        }
        if (formData.password.length < 6) {
            Swal.fire('Error', 'Password must be at least 6 characters long', 'error');
            return false;
        }
        if (formData.password !== formData.confirmPassword) {
            Swal.fire('Error', 'Passwords do not match', 'error');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (validateForm()) {
            setLoading(true);
            dispatch(travelerSignup(formData))
               
                .finally(() => setLoading(false)); 
        }
    };

useEffect(() => {
    if (message) {
      Swal.fire('Success', message || 'Signup Successful', 'success').then(() => {
        dispatch(clearMessages()); 
        navigate('/login')
      });
    } else if (error) {
      Swal.fire('Error', error || 'An error occurred').then(() => {
        dispatch(clearMessages());
      });
    }
  }, [ message, error, navigate, dispatch]);
    return (
        <div className='traveller-login-container'>
            <div className="traveller-login-main">
                <div className='traveller-login-mainContainer'>
                    <form onSubmit={handleSubmit} className="Traveller-login-main-leftSide">
                        <h2>Welcome to VadTrans!</h2>
                        <div className="traveler-login-leftside-item">
                            <label>Email</label>
                            <input className='password-inputHolder2-input' type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email' />
                        </div>
                        <div className="traveler-login-leftside-item">
                            <label>Password</label>
                            <div className="passContain">
                                          <input className='password-inputHolder3' type={visible ? "text" : 'password'} name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
                                          {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
                                          </div>
                        </div>
                        <div className="traveler-login-leftside-item">
                            <label>Confirm Password</label>
                            <div className="passContain">
                                          <input className='password-inputHolder3' type={visible ? "text" : 'password'}  name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder='Confirm Password' />
                                          {visible ? < FaEyeSlash  onClick={()=>setVisible(false)} /> : < IoMdEye   onClick={()=>setVisible(true)}/>}
                                          </div>
                            </div>
                        <div className='traveller-login-selectLine'>
                            <div className='signup-terms-section'>
                                <input type="checkbox" placeholder='remember me' /><label>I agree to <span>Terms</span> and <span>Privacy Policy</span></label>
                            </div>
                        </div>
                        <div className='traveller-login-btnHolder'>
                            <button type='submit' disabled={loading}>
                                {loading ? 'Signing up...' : 'Sign Up'}
                            </button>
                        </div>
                        <p className='p-tag-login'>Already have an account? <span>sign in</span></p>
                    </form>
                    <div className="Traveller-login-main-RightSide">
                        <img src={loginImage1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupTraveller;
