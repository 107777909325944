import React, { useState, useEffect } from 'react'
import "./styles.css"
import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg"
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg"
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg"
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg"
import { useSelector, useDispatch } from 'react-redux'
import { addCompanyVehicle, viewAllVehicles} from '../../../../actions/authActions'
import Swal from 'sweetalert2'
import { CiSearch } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";

const CompanyVehicles = () => {
  const dispatch = useDispatch();
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const [addVehicle, setAddVehicle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    // serialNumber: '',
    plateNumber: '',
    vehicleType: '',
    color: "",
    vehicleImage: null
  })  

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'vehicleImage') {
      // Handle image file input
      setFormData({
        ...formData,
        vehicleImage: files[0] // Assuming single file upload
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  

  const validateFormData = ()=>{
   
    if(!formData.plateNumber){
      Swal.fire('Error', 'Plate Number is required', 'error');
      return false;
    }
    if(!formData.vehicleType){
      Swal.fire('Error', 'Vehicle type is required', 'error');
      return false;
    }
    if(!formData.color){
      Swal.fire('Error', 'Color is required', 'error');
      return false;
    }
    if(!formData.vehicleImage){
      Swal.fire('Error', 'Image is required', 'error');
      return false;
    }
   
    return true;
  }

  const vehicles = useSelector((state) => state.auth.vehicles?.data || []);

    const handleAddVehicleBtn = () =>{
        setAddVehicle(true)
    }

   
    const handleSubmit = (e) => {
      e.preventDefault();
    
      if (validateFormData()) {
        setLoading(true);
    
        const data = new FormData();
        data.append('plateNumber', formData.plateNumber);
        data.append('vehicleType', formData.vehicleType);
        data.append('color', formData.color);
        data.append('vehicleImage', formData.vehicleImage);
    
        dispatch(addCompanyVehicle(data, userId, token))
          .then(() => {
            // Success Message
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Vehicle added successfully!',
            });
          })
          .catch((error) => {
            // Error Message
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response?.data?.message || 'Something went wrong!',
            });
          })
          .finally(() => setLoading(false));
      }
    };
    
    
    useEffect(() => {
      if (userId && token) {
        dispatch(viewAllVehicles(userId, token))
          .catch((error) => {
            console.error('Failed to fetch vehicles:', error);
          });

        
  
      } else {
        console.warn('userId or token not found');
      }
    }, [dispatch, userId, token]);
    

  return (
    <div className='companyVehicles-mainContainer'>
        <div className="company-dash-toplayer"></div>
        {
            addVehicle ? 
            < form className='add-newVehicle-conatiner' onSubmit={handleSubmit}>
               <h2>Add new Vehicles</h2>
                <div className="newVehicle-imgHolder">
                <input
                type="file"
                name="vehicleImage"
                accept="image/*"
                onChange={handleChange}
              />
                </div>
                <div className="AddcompanyVehicles-item">
                    <label htmlFor="">Serial Number</label>
                    <input type="text" name='serialNumber' value={formData.serialNumber} onChange={handleChange}/>
                </div>
                <div className="AddcompanyVehicles-item">
                    <label htmlFor="">Plate Number</label>
                    <input type="text" name='plateNumber' value={formData.plateNumber} onChange={handleChange} />
                </div>
                <div className="AddcompanyVehicles-item">
                    <label htmlFor="">Vehicle Type</label>
                    <input type="text" name='vehicleType' value={formData.vehicleType} onChange={handleChange} />
                </div>
                <div className="AddcompanyVehicles-item">
                    <label htmlFor="">Colour</label>
                    <input type="text" name='color' value={formData.color} onChange={handleChange} />
                </div>
                <div className="AddcompanyVehicles-item2">
                    <button type='submit' disabled={loading}>{loading? "Submitting..." : "Submit"}</button>
                </div>
            </form>
            
            :
            <>
       
            <h2>Vehicles</h2>
            <div className="companyVehicles-section1">
                <button onClick={handleAddVehicleBtn}> Add New</button>
            </div>
            {/* <div className="companyVehicles-section2">
    
            </div> */}
          <div className='company-tickets-maincontainer'>
          <div className='clientManagement-history'>
    <div className='clientManagement-history-searchbar'>
    <input type="text" />
    <img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
    <div className='filterContainer'> 
    <img className='filterContainer-img' src={filterIcon} alt="" />
      
    </div>
    </div>
    <div className='driverList-label-holder'>
    <div className='vehicle-image-label'></div>
    <p className='vehicle-label'>Serial No.</p>
    <p className='vehicle-label'>Plate Number</p>
    <p className='vehicle-label'>Vehicle type</p>
    <div className="vehicle-menuHolder"></div>
    
    
    </div>
    
      {vehicles.map((vehicle)=>(
        <div className='vehicle-list-holder'>
          <div className='vehicle-list-item' key={vehicle.id}>
        <div className='vehicle-image'>
            <img src={vehicle.vehicleImage.url} alt="" />
        </div>
        <p className='vehicle-label'>{vehicle.serialNo}</p>
        <p className='vehicle-label'>{vehicle.plateNumber}</p>
        <p className='vehicle-label'>{vehicle.vehicleType}</p>
      <div className='vehicle-menuHolder'>
      <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
      </div>
    </div>
    </div>
      ))}
    
    <div className='clientManagement-history-navigation'>
    <div className='clientManagement-history-item'>
      <img src={doubleArrowLeft} alt="" />
    </div>
    <div className='clientManagement-history-item2'>
      <img src={arrowLeft} alt="" />
    </div>
    <div className='clientManagement-history-item2-active'>
      <p>1</p>
    </div>
    <div className='clientManagement-history-item2'>
      <p>2</p>
    </div>
    <div className='clientManagement-history-item2'>
      <img src={arrowRight} alt="" />
    </div>
    <div className='clientManagement-history-item3'>
      <img src={doubleArrowRight} alt="" />
    </div>
    
    </div>
    
          </div>
            </div>

            <div className='company-tickets-maincontainer-mobile'>
                  <div className="company-tickets-maincontainer-mobile-sec1">
                    <div className="company-tickets-maincontainer-mobile-sec1a">
                        <CiSearch />
                        <input type="text" placeholder='search' />
                    </div>
                    <div className="company-tickets-maincontainer-mobile-sec1b">
                          <label htmlFor="">Latest</label> <FaAngleDown />
                    </div>
                  </div>
                  <div className='company-tickets-maincontainer-mobile-sec2'>
                  { vehicles ?
                    vehicles.map((vehicle)=>(
                      
                            <div className="company-tickets-maincontainer-mobile-sec2a" key={vehicle.id}>
                              
                              <div className='company-tickets-maincontainer-mobile-sec2a2'>
                                  <img src={vehicle.vehicleImage.url} alt="" />
                              </div>
                              <div className="company-tickets-maincontainer-mobile-sec2a1">
                                <p>Serial no.</p>
                                <p>{vehicle.serialNo}</p>
                              </div>
                              <div className="company-tickets-maincontainer-mobile-sec2a1">
                                <p>Plate no.</p>
                                <p>{vehicle.plateNumber}</p>
                              </div>
                              <div className="company-tickets-maincontainer-mobile-sec2a1">
                                <p>Vehicle type</p>
                                <p>{vehicle.vehicleType}</p>
                              </div>
                            </div>
                  
                    )) : <p style={{padding:'1rem'}}>
                      No vehicles added yet, kindly add a vehicle now!!!
                    </p>
                  }
                  </div>
                  
            </div>

            </>
        }
        
       
    </div>
  )
}

export default CompanyVehicles