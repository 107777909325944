
// import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';

// Create an instance of axios with base configuration
const api = axios.create({
    baseURL: 'https://vadtrans-api.onrender.com/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Driver signup action
export const driverSignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/signup-driver', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const forgotPasswordDriver = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/forgot-driver', formData);
        dispatch({ type: 'FORGET_PASSWORD_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'FORGET_PASSWORD_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
}

// Traveler signup action
export const travelerSignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/signup', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });

    } catch (error) {
        const errorMessage = 
        error.response && error.response.data && error.response.data.message 
            ? error.response.data.message 
            : error.message || 'An unknown error occurred';
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: errorMessage,
        });
    }
};

// Company signup action
export const companySignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/signup-company', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

// Admin signup action
export const adminSignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/admin-signup', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
       
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const login = (formData, userType) => async (dispatch) => {
    try {
        let endpoint = '';
        switch (userType) {
            case 'driver':
                endpoint = '/login-driver';
                break;
            case 'traveler':
                endpoint = '/login';
                break;
            case 'company':
                endpoint = '/login-company';
                break;
            case 'admin':
                endpoint = '/admin-login';
                break;
            default:
                throw new Error('Invalid user type');
        }

        const response = await api.post(endpoint, formData);
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });

       
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.User.userId);
    } catch (error) {
        const errorMessage = 
            error.response && error.response.data && error.response.data.message 
                ? error.response.data.message 
                : error.message || 'An unknown error occurred';
        
        dispatch({
            type: 'LOGIN_FAILURE',
            payload: { message: errorMessage }, // Ensures consistency
        });
    }
};



export const adminLogin = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/admin-login', formData);
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });

        // Store token and adminId in localStorage
        localStorage.setItem('token', response.data.token);
        const adminId = response.data.Admin?.adminId;
        if (adminId) {
            localStorage.setItem('adminId', adminId);
        } else {
            alert('Admin ID not found in response data');
        }
    } catch (error) {
        console.error("Login error:", error); // Debugging line
        const errorMessage = 
            error.response && error.response.data && error.response.data.message 
                ? error.response.data.message 
                : error.message || 'An unknown error occurred';
        
        dispatch({
            type: 'LOGIN_FAILURE',
            payload: { message: errorMessage }, // Ensures consistency
        });
    }
};

export const driverLogin = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/login-driver', formData);
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });

        // Store token and driverId in localStorage
        localStorage.setItem('token', response.data.token);
        const driverEmail = response.data.Driver?.email;
        const driverId = response.data.Driver?.userId;

        if (driverId) {
            localStorage.setItem('driverId', driverId);
            localStorage.setItem('driverEmail', driverEmail)
        } else {
            alert('Driver ID not found in response data');
        }
    } catch (error) {
        console.error("Login error:", error); // Debugging line
        const errorMessage = 
            error.response && error.response.data && error.response.data.message 
                ? error.response.data.message 
                : error.message || 'An unknown error occurred';
        
        dispatch({
            type: 'LOGIN_FAILURE',
            payload: { message: errorMessage }, // Ensures consistency
        });
    }
};




export const fetchUserData = (userId, token, userType) => async (dispatch) => {
    try {
        // Determine the endpoint based on user type
        let endpoint;
        switch (userType) {
            case 'traveler':
                endpoint = `/get-traveller/${userId}`;
                break;
            case 'driver':
                endpoint = `/get-driver/${userId}`;
                break;
            case 'transport_company':
                endpoint = `/get-company/${userId}`;
                break;
            case 'admin':
                endpoint = `/get-admin/${userId}`;
                break;
            default:
                throw new Error("Invalid user type");
        }

        const response = await api.get(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        const userData = response.data;

        // Dispatch success action with user data
        dispatch({ type: 'FETCH_USER_DATA_SUCCESS', payload: userData });

        // Save user data to localStorage
        localStorage.setItem('userData', JSON.stringify(userData));

    } catch (error) {
        dispatch({
            type: 'FETCH_USER_DATA_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};



export const updateUserData = (formData, userType, token) => async (dispatch) => {
    try {
        let endpoint = '';
        switch (userType) {
            case 'traveller':
                endpoint = '/update-profile';
                break;
            case 'driver':
                endpoint = '/update-driver';
                break;
            case 'company':
                endpoint = '/update-company';
                break;
            case 'admin':
                endpoint = '/update-admin';
                break;
            default:
                throw new Error('Invalid user type');
        }

        const response = await api.put(endpoint, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        dispatch({ type: 'UPDATE_USER_DATA_SUCCESS', payload: response.data });
    } catch (error) {
        const errorMessage = 
            error.response && error.response.data && error.response.data.message 
                ? error.response.data.message 
                : error.message || 'An unknown error occurred';
        dispatch({
            type: 'UPDATE_USER_DATA_FAILURE',
            payload: { message: errorMessage },
        });
    }
};



export const updateCompanyData = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.put(`/update-company/${userId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'UPDATE_COMPANY_DATA_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'UPDATE_COMPANY_DATA_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const addTerminals = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.post(`/add-terminal/${userId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_TERMINAL_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_TERMINAL_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const viewTerminals = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-terminals-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_TERMINAL_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TERMINAL_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const addCompanyVehicle = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.post(`/add-vehicle/${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_VEHICLE_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_FAILURE_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const viewAllVehicles = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-vehicles-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_VEHICLES_COMPANY_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TERMINAL_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};


export const viewOneVehicles = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-vehicle/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_VEHICLE_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_VEHICLE_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const addTrips = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.post(`/add-trip/${userId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_TRIPS_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_TRIPS_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const viewTripsCompany = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-trips-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_TRIPS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TRIPS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewTripsTraveller = (token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-tickets-admin', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_TRIPS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TRIPS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const createTicket = (formData, userId, token) =>async (dispatch) =>{    try{
    const response = await api.post(`/add-ticket/${userId}`, formData, {
        headers: {
            'Authorization' : `Bearer ${token}`
        },
        });
        dispatch({
            type: 'ADD_TICKET_SUCCESS',
            payload: response.data
        });
} catch (error){
    const errorMessage = 
        error.response && error.response.data && error.response.data.message 
            ? error.response.data.message 
            : error.message || 'An unknown error occurred';
    dispatch({
        type: 'BOOK_TICKET_FAILURE',
        payload: errorMessage
    });
}
};

export const bookTicket = (formData, userId, token) =>async (dispatch) =>{    try{
    const response = await api.post(`/book-ticket/${userId}`, formData, {
        headers: {
            'Authorization' : `Bearer ${token}`
        },
        });
        dispatch({
            type: 'BOOK_TICKET_SUCCESS',
            payload: response.data
        });
} catch (error){
    dispatch({
        type: 'BOOK_TICKET_FAILURE',
        payload: error.response? error.response.data.message : error.message
    });
}
};

export const selectSeat = (formData, bookedTicketId, token) => async (dispatch) => {
    try {
      const response = await api.put(`/select-seat/${bookedTicketId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Dispatch the success action
      dispatch({
        type: 'SELECT_SEAT_SUCCESS',
        payload: response.data,
      });
  
      // Return response data for further handling
      return { status: "success", payload: response.data };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to select seat. Please try again.";
  
      // Dispatch the failure action
      dispatch({
        type: 'SELECT_SEAT_FAILURE',
        payload: errorMessage,
      });
  
      // Return error for further handling
      return { status: "error", message: errorMessage };
    }
  };
  
  

  export const addLuggage = (formData, bookedTicketId, token) => async (dispatch) => {
    try {
        const response = await api.post(`/booking-luggage/${bookedTicketId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data", // Ensure Content-Type is set correctly for file uploads
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "ADD_LUGGAGE_SUCCESS",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "ADD_LUGGAGE_FAILURE",
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

  

export const contactUs = (formData, token) => async (dispatch) => {
    try {
        const response = await api.post('/reach-out', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        // Dispatch success action with user data
        dispatch({ type: 'CONTACT_US_SUCCESS', payload: response.data });

    } catch (error) {
        dispatch({
            type: 'CONTACT_US_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const forgetPassword = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/forgot', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Dispatch success action
        dispatch({
            type: 'FORGET_PASSWORD_SUCCESS',
            payload: response.data,
        });

    } catch (error) {
        dispatch({
            type: 'FORGET_PASSWORD_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};
export const resetPassword = (userId, formData) => async (dispatch) => {
    try {
        const response = await api.put(`/reset/${userId}`, formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Dispatch success action
        dispatch({
            type: 'RESET_PASSWORD_SUCCESS',
            payload: response.data,
        });

    } catch (error) {
        dispatch({
            type: 'RESET_PASSWORD_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const uploadProfilePicture = (file, userId, token) => async (dispatch) => {
    dispatch({ type: 'UPLOAD_PROFILE_PICTURE_REQUEST' });

    const formData = new FormData();
    formData.append('companyPicture', file);

    try {
        const response = await api.put(`upload-logo/`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        dispatch({
            type: 'UPLOAD_PROFILE_PICTURE_SUCCESS',
            payload: response.data.imageUrl,  // Assuming the response contains the image URL
        });

        // Optionally, save the image URL to localStorage
        localStorage.setItem('profilePicture', response.data);

    } catch (error) {
        dispatch({
            type: 'UPLOAD_PROFILE_PICTURE_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const uploadBusImages = (files, userId, token) => async (dispatch) => {
    dispatch({ type: 'UPLOAD_BUS_IMAGES_REQUEST' });

    const formData = new FormData();
    files.forEach((file) => {
        formData.append('photos', file); // Append each image under the 'photos' key
    });

    try {
        const response = await api.post(`bus-images/`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        dispatch({
            type: 'UPLOAD_BUS_IMAGES_SUCCESS',
            payload: response.data, // Server response data
        });
    } catch (error) {
        dispatch({
            type: 'UPLOAD_BUS_IMAGES_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};


export const uploadProfilePictureTraveller = (file, userId, token) => async (dispatch) => {
    dispatch({ type: 'UPLOAD_PROFILE_PICTURE_REQUEST' });

    const formData = new FormData();
    formData.append('profilePicture', file);

    try {
        const response = await api.put(`/upload-photo`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        dispatch({
            type: 'UPLOAD_PROFILE_PICTURE_SUCCESS',
            payload: response.data,  // Assuming the response contains the image URL
        });

        // Optionally, save the image URL to localStorage
        localStorage.setItem('profilePicture', response.data);

    } catch (error) {
        const errorMessage = 
        error.response && error.response.data && error.response.data.message 
            ? error.response.data.message 
            : error.message || 'An unknown error occurred';
    
        dispatch({
            type: 'UPLOAD_PROFILE_PICTURE_FAILURE',
            payload: { message: errorMessage },
        });
    }
};

export const getAllCompany = ( token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-all-company', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_ALL_COMPANIES_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_ALL_COMPANIES_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const getAvailableSeats = (ticketId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/get-seat-status/${ticketId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'GET_SEAT_STATUS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_ALL_COMPANIES_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};
export const getAllCompanyHome = ( token) =>async (dispatch) =>{
    try{
        const response = await api.get('/homepage-data', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_COMPANY_HOME_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_COMPANY_HOME_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewAllDrivers = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-drivers-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_DRIVER_COMPANY_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TERMINAL_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const startTrips = (formData, tripId, token) =>async (dispatch) =>{    try{
        const response = await api.post(`/start-trip/${tripId}`, formData, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'START_TRIPS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'START_TRIPS__FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const stopTrips = (formData, tripId, token) =>async (dispatch) =>{    try{
    const response = await api.put(`/register-stops/${tripId}`, formData, {
        headers: {
            'Authorization' : `Bearer ${token}`
        },
        });
        dispatch({
            type: 'START_TRIPS_SUCCESS',
            payload: response.data
        });
} catch (error){
    dispatch({
        type: 'START_TRIPS__FAILURE',
        payload: error.response? error.response.data.message : error.message
    });
}
};


export const endTrips = (formData, tripId, token) =>async (dispatch) =>{    try{
    const response = await api.post(`/end-trips/${tripId}`, formData, {
        headers: {
            'Authorization' : `Bearer ${token}`
        },
        });
        dispatch({
            type: 'START_TRIPS_SUCCESS',
            payload: response.data
        });
} catch (error){
    dispatch({
        type: 'START_TRIPS__FAILURE',
        payload: error.response? error.response.data.message : error.message
    });
}
};

export const trackTrips = (formData, token) => async (dispatch) => {
    try {
      const response = await api.get('/track-trip', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      dispatch({
        type: 'TRACK_TRIPS_SUCCESS',
        payload: response.data,
      });
    } catch (error) {
      // Handle any errors that occur during the request
      dispatch({
        type: 'TRACK_TRIPS_FAILURE',
        payload: error.response ? error.response.data.message : error.message,
      });
    }
  };

export const viewCompanyTickets = (userId, token) => async (dispatch) => {
    try{
        const response = await api.get(`/view-tickets-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_COMPANY_TICKETS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_COMPANY_TICKETS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
  };

  export const viewBookedTicketsUser = (token) => async (dispatch) => {
    try{
        const response = await api.get('/view-bookedticket-user', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_COMPANY_TICKETS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_COMPANY_TICKETS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
  };

export const viewBookedTicket = (bookedTicketId, token) => async (dispatch) => {
    try{
        const response = await api.get(`/view-bookedticket-user/${bookedTicketId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_COMPANY_TICKETS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_COMPANY_TICKETS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
  };
  export const driverTrips = (formData, token) => async (dispatch) => {
    try {
        const response = await api.post(
            '/driver-trips',
            formData, 
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        dispatch({
            type: 'VIEW_DRIVERTRIPS_SUCCESS',
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: 'VIEW_DRIVERTRIPS_FAILURE',
            payload: error.response ? error.response.data.message : error.message
        });
    }
};


export const viewAllTravellersAdmin = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-all-traveller', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_ALL_TRAVELLERS_ADMIN_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_ALL_TRAVELLERS_ADMIN_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewAllCompanyAdmin = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-all-company', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_ALL_COMPANIES_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_ALL_TRAVELLERS_ADMIN_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewTripsAdmin = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-tickets-admin/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_TRIPS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TRIPS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewTicketsAdmin = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-tickets-admin/', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_TICKETS_ADMIN_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type:  'VIEW_TICKETS_ADMIN_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewAllPayments = (token) =>async (dispatch) =>{
    try{
        const response = await api.get('/get-payment', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_ALL_PAYMENT_ADMIN_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type:  'VIEW_TICKETS_ADMIN_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const approveClientMembership = (formData, transCompanyId, token) => async (dispatch) => {
    try {
        const response = await api.put(`/client-membership/${transCompanyId}`,
            formData, 
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        dispatch({
            type: 'APPROVE_CLIENT_MEMBERSHIP_SUCCESS',
            payload: response.data
        });
    } catch (error) {
        const errorMessage = 
            error.response && error.response.data && error.response.data.message 
                ? error.response.data.message 
                : error.message || 'An unknown error occurred';

        dispatch({
            type: 'APPROVE_CLIENT_MEMBERSHIP_FAILURE',
            payload: { message: errorMessage }
        });
    }
};

export const viewFAQsAdmin = (token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-faqs', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_FAQS_ADMIN_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type:  'VIEW_FAQS_ADMIN_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const addFAqs = (formData, token) => async (dispatch) => {
    try {

        const response = await api.post('/add-faq', formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_FAQS_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_FAQS_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const viewFAqs = ( token) => async (dispatch) => {
    try {

        const response = await api.get('/view-faqs', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'VIEW_FAQS_ADMIN_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_FAQS_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const updateAdminData = (formData, token) => async (dispatch) => {
    try {

        const response = await api.put('/update-admin', formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'UPDATE_ADMIN_DATA_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'UPDATE_ADMIN_DATA_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const fetchAdminData = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/get-admin/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'FETCH_USER_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type:  'FETCH_USER_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const fetchAvailableSeats = (tripId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/get-seat-status/${tripId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'FETCH_SEAT_STATUS_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type:  'FETCH_SEAT_STATUS_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const getNotificationsAdmin = (token) => async(dispatch)=>{
    try{
        const response = await api.get('/notifications', {
            headers:{
                    'Authorization' : `Bearer ${token}`
            },
        })
        dispatch({
            type:'GET_NOTIFICATIONS_SUCCESS',
            payload: response.data
        });
    } catch (error){
            dispatch({
                type: "GET_NOTIFICATIONS_FAILURE",
                payload: error.response? error.response.data?.message : error.message
            })
    }
}

export const viewCommisionedBal = (token) => async(dispatch)=>{
    try{
        const response = await api.get('/view-balance', {
            headers:{
                    'Authorization' : `Bearer ${token}`
            },
        })
        dispatch({
            type:'GET_NOTIFICATIONS_SUCCESS',
            payload: response.data
        });
    } catch (error){
            dispatch({
                type: "GET_NOTIFICATIONS_FAILURE",
                payload: error.response? error.response.data?.message : error.message
            })
    }
}

export const viewCompanyBal = (token) => async (dispatch) => {
    try {
      const response = await api.get('/get-balance', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const balance = response.data.balance;
  
      // Save balance to local storage
      localStorage.setItem('walletBalance', JSON.stringify(balance));
  
      // Dispatch success action
      dispatch({
        type: 'VIEW_BALANCE_SUCCESS',
        payload: balance,
      });
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
  
      dispatch({
        type: 'GET_NOTIFICATIONS_FAILURE',
        payload: error.response ? error.response.data?.message : error.message,
      });
    }
  };
  

export const sendEmails = (formData, token) => async (dispatch) => {
    try {

        const response = await api.post('/mail-notifications', formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_FAQS_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_FAQS_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const makePaymentTraveller = (formData, token) => async (dispatch) => {
    try {
      const response = await api.post("/initialize-payment", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({
        type: "PAYMENT_TRAVELLER_SUCCESS",
        payload: response.data,
      });
  
      return response.data; // ✅ Return API response
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
  
      dispatch({
        type: "UPDATE_ADMIN_DATA_FAILURE",
        payload: errorMessage,
      });
  
      throw new Error(errorMessage); // ✅ Throw error for better handling
    }
  };
  

export const companywithdrawal = (formData, token) => async (dispatch) => {
    try {
        const response = await api.post('/withdraw', formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'WITHDRAWAL_SUCCESS',
            payload: response.data,
        });

        // Return the response to be handled in the component
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;

        dispatch({
            type: 'WITHDRAWAL_FAILURE',
            payload: errorMessage,
        });

        // Throw the error to be caught in the component
        throw new Error(errorMessage);
    }
};


export const updateAccountDetails = (formData, token) => async (dispatch) => {
    try {
        const response = await api.put('/bank-details', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: 'PAYMENT_TRAVELLER_SUCCESS',
            payload: response.data,
        });

        // Return the response data for handling in the component
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;

        dispatch({
            type: 'UPDATE_ADMIN_DATA_FAILURE',
            payload: errorMessage,
        });

        // Throw an error to be caught in the component
        throw new Error(errorMessage);
    }
};


export const clearMessages = () => ({
    type: 'CLEAR_MESSAGES',
});
export const logout = () => (dispatch) => {
    localStorage.clear();
  
    dispatch({ type: 'LOGOUT' });
  };
  
