import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { viewAllPayments } from '../../../../../../actions/authActions'
import "./Payment.css"
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import { TfiLayoutMenuSeparated } from "react-icons/tfi";
import doubleArrowLeft from "../dashContent/images/angle-double-left.svg"
import arrowLeft from "../dashContent/images/angle-left.svg"
import arrowRight from "../dashContent/images/angle-right.svg"
import doubleArrowRight from "../dashContent/images/angle-double-right.svg"

const Payment = () => {
const dispatch = useDispatch();
const token = localStorage.getItem('token')
const payments = useSelector((state) => state.auth?.payments?.data)


useEffect (()=>{
    dispatch(viewAllPayments(token))
}, [dispatch, token])
  return (
    <div className='payment-main-container'>
      <div className='paymentHeader-container'>
        <DashboardHeader/>
      </div>
      <div className='payment-container'>
        <div className='payment-Header'>Payments</div>
        <div className='payments-history'>
          <div className='payment-history-searchbar'>
            <input type="text" />
            <img src={searchIcon} alt="" />
            <div className='filterContainer'> 
            <img src={filterIcon} alt="" />
              
            </div>
          </div>
          <div className='payment-history-labels'>
            <p>Payment ID</p>
            <p> Status</p>
            <p>Amount</p>
            <p>Date</p>
          </div>
          <div className='payment-list-holder'>
            {payments? 
            payments.map((payment)=>(
              <div className='payment-list-item' key={payment._id}>
              <p>{payment.reference}</p>
              <p>{payment.status}</p>
              <p>{payment.amount}</p>
              <p>{payment.date_Time.slice(0,10)}</p>
              <div style={{width:'100px', height:'90%', alignItems:'center', display:'flex', justifyContent:'center', cursor:'pointer'}}><TfiLayoutMenuSeparated /></div>
              
            </div>
            )):
            (
              <h5 style={{padding:'2rem'}}>No Payment available now</h5>
            )
          }
            
            
          </div>
          <div className='payment-history-navigation'>
            <div className='payment-history-item'>
              <img src={doubleArrowLeft} alt="" />
            </div>
            <div className='payment-history-item2'>
              <img src={arrowLeft} alt="" />
            </div>
            <div className='payment-history-item2-active'>
              <p>1</p>
            </div>
            <div className='payment-history-item2'>
              <p>2</p>
            </div>
            <div className='payment-history-item2'>
              <img src={arrowRight} alt="" />
            </div>
            <div className='payment-history-item3'>
              <img src={doubleArrowRight} alt="" />
            </div>

        </div>
          
        </div>
       
      </div>
    </div>
  )
}

export default Payment
